import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function AppContact(){
    return(
        <section id="contact" className='contact-block'>
    <Container fluid>
        <div className='title-holder'>
            <h2>Contact Us</h2>
        </div>
        <div className='contact-div'>
        <Row>
            <Col sm={12} md={6}>
                <div className='google-map-container'>
                    <iframe className='google-map' title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15488.429604502782!2d75.54644720069926!3d13.952210223535397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbbafd9cc89db59%3A0x889882ecbaff542!2sSri%20Nilaya!5e0!3m2!1sen!2sin!4v1710331572231!5m2!1sen!2sin" 
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </Col>
            <Col sm={12} md={6}>
                <div className='contact-info'>
                    <div className="contact-info-item1">
                        <i className="fas fa-envelope" style={{ fontSize: '28px', color: '#038D4C'}}></i>
                        <p>Email: ceo@parnacreations.in</p>
                    </div>

                    <div className="contact-info-item2">
                        <i className="fas fa-map-marker-alt" style={{ fontSize: '28px', color: '#038D4C' }}></i>
                        <p>Address: Parna Creations (P) Ltd <br></br>
                            75/76" Sri Nilaya” Behind K G P Hospital
                            Kallahalli, Vinobhanagara, Shivamogga-577204
                        </p>
                    </div>

                    <div className="contact-info-item3">
                        <i className="fas fa-phone" style={{ fontSize: '28px', color: '#038D4C'}}></i>
                        <p>Phone:+91 9448203888</p>
                    </div>
                </div>
            </Col>
        </Row>
        </div>
    </Container>
</section>

        
    )
}