import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppHeader from './components/header'
import AppHome from './components/home'
import AppWork from './components/works'
import AppAbout from './components/about'
import AppMission from './components/mission'
import AppProduct from './components/product';
import AppTeam from './components/teams';
import AppContact from './components/contact';
import AppFooter from './components/footer';
function App() {
  return (
    <div className="App">
        <header id="header">
          <AppHeader />
        </header>
        <main>
        <AppHome />
        {/* <AppWork /> */}
        <AppAbout />
        <AppMission />
        <AppProduct />
        <AppTeam />
        <AppContact/>
       
        </main>
        <footer id="footer">
          <AppFooter />
        </footer>
    </div>
  );
}

export default App;
