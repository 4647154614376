import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import homeimage from '../assets/images/Parna Creations.jpg'
function AppHome(){
    return(
        <section id="home" className='home-block'>
        {/* <Container>
        <Row>
        <Col sm={8}>
            <div className='homecontent'>
              <div className='homename1'>
                <h1>parna</h1>
              </div>
              <div className='homename2'>
                <h1>creations</h1>
              </div>
              </div>
        </Col>
        <Col sm={4}></Col>
        </Row>
      </Container> */}
     
        <Image src={homeimage} className="custom-image" alt="Your Image" />
       
    
      </section>
    )
}

export default AppHome;