// import Container from 'react-bootstrap/Container';
// import Carousel from 'react-bootstrap/Carousel';

// export default function AppProduct(){
//     return(
        
//         <section id="product" className='product-block'>
//             <Container fluid>
//              <Carousel controls={false}>
//                <Carousel.Item>
//                   <h3>About Our Product</h3>
//                     <p>Our product, Vegan Leather, stands as the best alternative to animal leather and has gained 
//                         popularity in today's society. A recent survey by INFINIUM Research reveals that one-third of 
//                         people in the United Kingdom and 23% in the United States prefer not to use animal leather. 
//                         This trend isn't limited to these countries; globally, people are shifting towards Vegan Leather, 
//                         indicating significant market potential. Vegan Leather is the only 100% eco-sustainable material,
//                          ensuring no adverse environmental effects. Unlike Animal Leather, which raises concerns about 
//                          environmental pollution, our production process involves no cutting or peeling of any tree parts, 
//                          and no chemicals are used at any stage. Therefore, we proudly affirm that our product is 100% 
//                          natural and eco-friendly.</p>
                
//                </Carousel.Item>
//                <Carousel.Item>
                 
//                    <h3>About the Process</h3>
//                       <p>Areca leaves and husk contain high-quality fibers. The process begins by collecting Areca leaves 
//                         and husks, followed by the extraction of fibers. After extraction, the fibers undergo a cleaning
//                          process to ensure purity. These cleaned fibers are then blended with biodegradable gum, serving 
//                          as the foundation for our vegan leather manufacturing.</p>
                 
//                </Carousel.Item>
//             </Carousel>
//             </Container>
//         </section>
//     )
// }

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';

export default function AppProduct() {
    return (
        <section id="product" className='product-block'>
            <Container fluid>
                <Carousel controls={false} className="my-carousel">
                    <Carousel.Item>
                        <div className="carousel-content">
                            <h3>About Our Product</h3>
                            <p>Our product, Vegan Leather, stands as the best alternative to animal leather and has gained 
                                popularity in today's society. A recent survey by INFINIUM Research reveals that one-third of 
                                people in the United Kingdom and 23% in the United States prefer not to use animal leather. 
                                This trend isn't limited to these countries; globally, people are shifting towards Vegan Leather, 
                                indicating significant market potential. Vegan Leather is the only 100% eco-sustainable material,
                                ensuring no adverse environmental effects. Unlike Animal Leather, which raises concerns about 
                                environmental pollution, our production process involves no cutting or peeling of any tree parts, 
                                and no chemicals are used at any stage. Therefore, we proudly affirm that our product is 100% 
                                natural and eco-friendly.</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="carousel-content">
                            <h3>About the Process</h3>
                            <p>Areca leaves and husk contain high-quality fibers. The process begins by collecting Areca leaves 
                                and husks, followed by the extraction of fibers. After extraction, the fibers undergo a cleaning
                                process to ensure purity. These cleaned fibers are then blended with biodegradable gum, serving 
                                as the foundation for our vegan leather manufacturing.</p>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </section>
    )
}
