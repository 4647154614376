import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import teamimage1 from '../assets/images/team1.jpg'
import teamimage2 from '../assets/images/team1.jpg'
import teamimage3 from '../assets/images/team1.jpg'
import teamimage4 from '../assets/images/team1.jpg'

export default function AppTeam(){
    return(
        <section id="teams" className='teams-block'>
            <Container fluid>
                <div className='title-holder'>
                    <h2>Our Team</h2>
                </div>
                <Row>
                  <Col sm={3}>
                   {/* <div className='image'>
                     <Image src={teamimage1} />
                       <div className='overlay'>
                     <div className='socials'>
                        <ul>
                          <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                      </div>
                  </div>
              </div> */}
              <div className='content'>
                <h3>Mr.Suresh</h3>
               <span className='designation'>CEO</span>
              </div>
           </Col>
           <Col sm={3}>
                   {/* <div className='image'>
                     <Image src={teamimage2} />
                       <div className='overlay'>
                     <div className='socials'>
                        <ul>
                          <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                      </div>
                  </div>
              </div> */}
              <div className='content'>
                <h3>Mrs.Mythili Suresh</h3>
               <span className='designation'>Director and Chairman</span>
              </div>
           </Col>
           <Col sm={3}>
                   {/* <div className='image'>
                     <Image src={teamimage3} />
                       <div className='overlay'>
                     <div className='socials'>
                        <ul>
                          <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                      </div>
                  </div>
              </div> */}
              <div className='content'>
                <h3>Ms.Bhoomika S Suresh</h3>
               <span className='designation'>Managing Director</span>
              </div>
           </Col>
           <Col sm={3}>
                   {/* <div className='image'>
                     <Image src={teamimage4} />
                       <div className='overlay'>
                     <div className='socials'>
                        <ul>
                          <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                      </div>
                  </div>
              </div> */}
              <div className='content'>
                <h3>Ms.Poorvika S Rao</h3>
               <span className='designation'>Director</span>
              </div>
           </Col>
            </Row>
            </Container>
        </section>
    )
}