import Container from 'react-bootstrap/Container';
export default function AppMission(){
    return(
        <section id="mission" className='mission-block'>
        <Container fluid>
            
                <div className='Missiontitle'>
                    <h2>Our Mission</h2>
                    <p>Utilizing leftover agricultural by-products,our mission is to to create new green initiatives  and 
                        contribute to making the planet greener.</p>
                </div>
                <div className='Visiontitle'>
                    <h2>Our Vision</h2>
                    <p>Improving rural management for sustainability, creating new employment opportunities, and providing 
                        additional income to the rural ecosystem.</p>
                </div>
                
            </Container>
            </section>
    )
}