import Container from 'react-bootstrap/Container';
export default function AppAbout(){
    return(
    <section id="about" className='about-block'>
            <Container fluid>
                <div className='title-holder'>
                    <h2>About Us</h2>
                </div>
                <div className='aboutcontent'>
                    <p>
                    At Parna Creations (P) Ltd, our primary focus lies in the conversion of areca palm leaf fiber
                     and husk fiber into biodegradable vegan leather. The raw material used is an unused agro by-product, 
                     contributing to waste-to-wealth conversion.Our project provides a secondary income to farmers and also
                      contributes to better rural management for sustainability, creating new employment opportunities and 
                      additional income within the rural ecosystem. As per the Recent Government survey, there are more than 
                      128,000+ hectares of land is under Areca Cultivation in Shimoga district alone, if we take Chickmagalore,
                       Davanagere, Chithradurga and Uttarakannada district it will account more than 850000+ hectare is under 
                       areca cultivation as a result there are ample of Raw materials available for the process.
                    </p>
                    <p>
                    We have a technology transfer (For biodegradable gum) agreement with ICAR-Kolkata-NINFET 
                    (National Institute of Natural Fibres & Engineering Technology) which is one and only fibre technology Institute
                     of India. They are supporting us to develop the product (NDA & MOU already done) for the same.In contrast to 
                     existing vegan leathers utilizing polyurethane, our product stands out as 100% biodegradable, emitting zero 
                     carbon and causing zero pollution.
                    </p>
                </div>
            </Container>          
    </section>
    )
}